import request from '@/utils/request'

export function getData(params) {
  return request({
    url: '/common/iosashv',
    method: 'get',
    params,
  })
}

export function getWxConfig(params) {
  return request({
    url: '/common/wxconfig',
    method: 'get',
    params,
  })
}

export function getUserCenter(params) {
  return request({
    url: '/user/center',
    method: 'get',
    params,
  })
}

/**
 * 获取微信网页授权登录url
 */
export function getAuthLoginUrl(params) {
  return request({
    url: '/common/webLoginUrl',
    method: 'get',
    params,
  })
}
