export function isWechat() {
  return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
}

export function isAlipay() {
  return navigator.userAgent.toLowerCase().indexOf('alipay') !== -1
}

export function currentClient() {
  const userAgent = navigator.userAgent.toLowerCase()
  if (userAgent.indexOf('micromessenger') !== -1) {
    return 'wechat'
  } else if (userAgent.indexOf('alipay') !== -1) {
    return 'alipay'
  } else {
    return ''
  }
}
