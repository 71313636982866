import wx from 'weixin-js-sdk'
import { getWxConfig } from '@/api/main'

const wxinit = (jsApiList, openTagList = []) => {
  return new Promise((resolve, reject) => {
    const url = location.href.split('#')[0]
    getWxConfig({
      url,
    })
      .then((res) => {
        if (res.status != 1) {
          reject()
          return false
        }
        // console.log('config', res)
        // appId: '', // 必填，公众号的唯一标识
        // timestamp: '', // 必填，生成签名的时间戳
        // nonceStr: '', // 必填，生成签名的随机串
        // signature: '', // 必填，签名
        wx.config(
          Object.assign(
            {
              debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
              jsApiList, // 必填，需要使用的 JS 接口列表
              openTagList, // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
            },
            res.data
          )
        )
        wx.ready(() => {
          // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，
          // config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。
          // 对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中。
          resolve(wx)
        })

        wx.error(function (err) {
          // config信息验证失败会执行 error 函数，如签名过期导致验证失败，
          // 具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
          // console.log('wx.error', err)
          reject(err)
        })
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function initWxConfig(jsApiList) {
  return new Promise((resolve, reject) => {
    const url = location.href.split('#')[0]
    getWxConfig({
      url,
    })
      .then((res) => {
        if (res.status != 1) {
          reject()
          return false
        }
        // console.log('config', res)
        // appId: '', // 必填，公众号的唯一标识
        // timestamp: '', // 必填，生成签名的时间戳
        // nonceStr: '', // 必填，生成签名的随机串
        // signature: '', // 必填，签名
        wx.config(
          Object.assign(
            {
              // debug: true, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
              jsApiList, // 必填，需要使用的 JS 接口列表
            },
            res.data
          )
        )
        wx.ready(() => {
          // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，
          // config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。
          // 对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中。
          resolve(wx)
        })

        wx.error(function (err) {
          // config信息验证失败会执行 error 函数，如签名过期导致验证失败，
          // 具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
          // console.log('wx.error', err)
          reject(err)
        })
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * 调起微信扫一扫接口
 * @param {*} param
 */
export function scanQRCode(param) {
  wxinit(['scanQRCode'])
    .then((wx) => {
      wx.scanQRCode(param)
    })
    .catch(() => {})
}

/**
 * 发起一个微信支付请求
 * @param {*} param
 */
export function chooseWXPay(param) {
  wxinit(['chooseWXPay'])
    .then((wx) => {
      wx.chooseWXPay(param)
    })
    .catch(() => {})
}
