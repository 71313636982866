import request from '@/utils/request'

export function doBind(data) {
  return request({
    url: '/code/bind',
    method: 'post',
    data,
  })
}

export function doUnbind(data) {
  return request({
    url: '/code/unbind',
    method: 'post',
    data,
  })
}

/**
 * 上报识别的二维码内容
 */
export function doScan(data) {
  return request({
    url: '/common/scan2',
    method: 'post',
    data,
  })
}

// /**
//  * 获取授权状态
//  */
// export function getAuthStatus(param) {
//   return request({
//     url: '/code/authStatus',
//     method: 'get',
//     param,
//   })
// }
