import { onMounted, defineComponent, reactive, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { setToken } from '@/utils/token';
import { getUserCenter } from '@/api/main';
import { doScan } from '@/api/code';
import { initWxConfig } from '@/utils/wxsdk';
import { currentClient } from '@/utils/until';
import { Toast } from 'vant';
import 'vant/es/toast/style';
export default defineComponent({
  name: 'User',
  components: {},

  setup() {
    const state = reactive({
      wx: null,
      data: {
        code_count: '--',
        order_count: '--',
        is_auth: 0
      }
    });
    const route = useRoute();
    const router = useRouter();
    /**
     * 点击我的挪车码
     */

    const onMyCode = () => {
      router.push({
        name: 'Code'
      });
    };
    /**
     * 点击我的订单
     */


    const onMyOrder = () => {
      router.push({
        name: 'Order'
      });
    };
    /**
     * 扫码挪车
     */


    const onScan = () => {
      const client = currentClient(); // const client = ''

      switch (client) {
        case 'wechat':
          state.wx.scanQRCode({
            needResult: 1,
            // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ['qrCode'],
            // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
              let codeStr = res.resultStr,
                  sn = codeStr.substring(codeStr.lastIndexOf('/') + 1);
              doOpScanRes(sn);
            },
            fail: () => {
              Toast('扫码失败');
            }
          });
          break;

        case 'alipay':
          window.AlipayJSBridge.call('scan', {
            type: 'qr' // 扫描类型  qr 二维码

          }, res => {
            if (res.error) {
              switch (res.error) {
                case 10:
                  Toast('已取消');
                  break;

                case 11:
                  Toast('网络异常，请重试');
                  break;
              }
            } else if (res.codeContent) {
              let codeStr = res.codeContent,
                  sn = codeStr.substring(codeStr.lastIndexOf('/') + 1);
              doOpScanRes(sn);
            }
          });
          break;

        default:
          Toast('暂不支持扫码'); // doOpScanRes('B10002')

          break;
      }
    };
    /**
     * 处理扫码结果
     * @param {string} sn 编号
     */


    const doOpScanRes = async sn => {
      const {
        data,
        msg,
        status
      } = await doScan({
        sn
      });

      if (status == 1) {
        // 状态正常
        if (data.self) {
          // 自己的码跳转到码列表
          router.push({
            name: 'Code'
          });
        } else {
          router.push({
            name: 'Scan',
            query: {
              sn,
              license_plate_number: data.license_plate_number,
              is_auth: data.is_auth ? 1 : 0,
              need_auth: data.need_auth ? 1 : 0,
              need_pay: data.need_pay ? 1 : 0
            }
          });
        }
      } else if (status == 5001) {
        // 未初始化
        router.push({
          name: 'Code',
          params: {
            code_sn: sn
          }
        });
      } else {
        Toast(msg);
      }
    };
    /**
     * 常见问题
     */


    const onToQuestion = () => {
      router.push({
        name: 'Question'
      });
    };

    const setTokenInit = () => {
      return new Promise(resolve => {
        let query = route.query;
        const token = query.token || '';

        if (token) {
          setToken(query.token);
        }

        resolve();
      });
    };

    const getCenterData = async () => {
      const {
        data
      } = await getUserCenter();
      state.data = data;
    };
    /**
     * 我的钱包
     */


    const onWallet = () => {
      router.push({
        name: 'Wallet'
      });
    };

    watch(() => router.currentRoute.value.name, (to, from) => {
      if (to == 'User' && from == 'Code') {
        getCenterData();
      }
    }, {
      immediate: true
    });
    onMounted(async () => {
      const toast = Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      await setTokenInit();
      initWxConfig(['scanQRCode']).then(wx => {
        state.wx = wx;
      });
      getCenterData();
      toast.clear();
      let query = route.query;

      if (query.sn) {
        // 包含了sn直接跳转挪车页面
        doOpScanRes(query.sn);
      }
    });
    return { ...toRefs(state),
      onScan,
      onToQuestion,
      onMyCode,
      onMyOrder,
      onWallet
    };
  }

});